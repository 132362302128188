<template>
  <div class="reserved-area-page calendar">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <form>
            <div class="calendar-wrapper">
              <v-date-picker :attributes="calendarAttributes" class="big-calendar" value="" :min-date='new Date()'></v-date-picker>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Horários</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12">
                    <div class="reserved-area-hours d-flex justify-content-start align-items-start">
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="workout-pt-author-slider-item">
                        <div class="workout-hours-item">
                          <label>
                            <input type="radio" name="author-slider-item-radio">
                            <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                              <span>14:00</span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Adicionar Novo Horário</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";

export default {
  name: "reserved-area-creator-calendar",
  components: {
    reservedAreaMenu,
  },
  data: function () {
    return {
      calendarAttributes: [
        {
          dot: {
            style: {
              backgroundColor: '#a01e1d',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
        {
          dot: {
            style: {
              backgroundColor: '#777777',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
      ],
    }
  },
}
</script>